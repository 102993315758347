.h-3 {
  height: 3rem;
}

.bg-blue {
  background-color: #00439c;
}

.bg-yellow {
  background-color: #f4e93d;
}

