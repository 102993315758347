.border-black {
  border-color: #333333 !important;
}

.currency-toggle {
  display: flex;
  border: 1px solid #ccc; /* Outer border with 2px padding */
  overflow: hidden;
  padding: 4.2px; /* Padding for the outer border */
  height: 42px;
  width: 180px;
}

.ml-20 {
  margin-left: 13rem;
}

.currency-button {
  flex: 1;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  transition:
    background-color 0.3s,
    color 0.3s;

  margin-right: 8px;

  &:hover {
    background-color: #004ba7;
    color: #fff;
  }
}

.currency-selected {
  background-color: #004ba7;
  color: #fff;
}

.black-background {
  background-color: #333333;
  color: #f5f7fa;
}

.error {
  color: #e53e3e;
}
