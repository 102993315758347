.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8rem;
  grid-row-gap: 2rem;
  margin: 20px 0;
  padding: 65px 80px;
}

.col-span-half {
  grid-column: span 0.5;
}

.col-span-2 {
  grid-column: span 2;
}

.col-span-3 {
  grid-column: span 3;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333333;
}

.radio-label {
  margin-right: 5rem; /* Increase the spacing as needed */
}

// Black text style
.blackText {
  @extend .label; // Inherit styles from the common label class
  color: black;
}

// Gray text style
.grayText {
  @extend .label; // Inherit styles from the common label class
  color: gray;
}

.input-field {
  width: 100%;
  padding: 6.5px;
  border: 1px solid #ccc;
}

.error-message {
  color: #e53e3e;
  margin-top: 8px;
}

.error {
  color: #e53e3e;
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-label {
  margin-left: 8px;
}

.currency-toggle {
  display: flex;
  border: 1px solid #ccc; /* Outer border with 2px padding */
  
  overflow: hidden;
  padding: 4.2px; /* Padding for the outer border */
  height: 42px;
  width: 180px;
}

.ml-20 {
  margin-left: 13rem;
}

.currency-button {
  flex: 1;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  transition:
    background-color 0.3s,
    color 0.3s;
  
  margin-right: 8px;

  &:hover {
    background-color: #004ba7;
    color: #fff;
  }
}

.radio-label input {
  margin-right: 8px;
}

.currency-selected {
  background-color: #004ba7;
  color: #fff;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.for {
  color: #333333;
}

.database {
  color: #646566;
}

.w-full {
  width: 50rem !important;
}

.flex-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-container {
  display: flex;
}

.-mt-1 {
  margin-top: -1rem;
}

.black-background {
  background-color: #333333;
  color: #f5f7fa; // Set text color to white or another contrasting color
}

/* Add the following CSS code to your stylesheet */
.rtl-direction {
  direction: rtl;
}
