.bg-blue {
  background-color: #00439c;
}

.text-black {
  color: "#333333";
}

.mb-1 {
  margin-bottom: 1rem;
}

.w-22 {
  width: 22rem;
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  // gap: 12rem; /* Adjust the gap as needed */
}

.custom-column {
  /* Optional: Add styles for each column */
}
