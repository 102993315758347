.dropdown-menu {
  top: calc(100% + 10px);
  right: -5px;
  z-index: 50;
  position: absolute;
  display: none;
}

.dropdown-menu.block {
  display: block;
}
