.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
  padding: 8.5rem;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1d1d1f;
  height: 200px;
  width: 280px;
  transition: background 0.3s ease-in-out;
  // font-size: 26px;
  font-size: 22px;
  line-height: 1.16667;
  font-weight: 500;
  letter-spacing: 0.009em;
  // border-radius: 22px;
  // font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
  //   "Arial", sans-serif;
}

.grid-item.large {
  grid-column: span 2;
}

.grid-item {
  // font-size: 1rem;
  background-color: #ffffff;
  transition: background 0.3s ease-in-out;
  // font-weight: bold;

  &:hover {
    background: linear-gradient(to bottom, #00439c 100%, #0059bd 0%);
    color: #ffffff;
  }
}

.macbook-button {
  position: relative;
  overflow: hidden;
  transition:
    transform 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.macbook-button:hover {
  transform: scale(0.98);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 8px 16px rgba(0, 0, 0, 0.1);
}

.notification-dot {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background-color: #ff4d4f;
  border-radius: 50%;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #ff4d4f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
