.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333333;
}

.radio-label {
  margin-right: 5rem; /* Increase the spacing as needed */
}

// Black text style
.blackText {
  @extend .label; // Inherit styles from the common label class
  color: black;
}

// Gray text style
.grayText {
  @extend .label; // Inherit styles from the common label class
  color: gray;
}

.input-field {
  width: 100%;
  padding: 6.5px;
  border: 1px solid #ccc;
}

.error-message {
  color: #e53e3e;
  margin-top: 8px;
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-label {
  margin-left: 8px;
}

.preview-button {
  color: #ffffff;
  width: 238px;
  height: 95px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.preview-button:hover {
  background-color: #3182ce;
}

.ml-20 {
  margin-left: 13rem;
}

.radio-label input {
  margin-right: 8px;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.for {
  color: #333333;
}

.database {
  color: #646566;
}

.w-full {
  width: 50rem !important;
}

.black-background {
  background-color: #333333;
  color: #f5f7fa; // Set text color to white or another contrasting color
}

.blue-background {
  background: linear-gradient(to bottom, #00439c, #0059bd);
}

.mb-5 {
  margin-bottom: 2rem;
}

.-mt-1 {
  margin-top: -1rem;
}

.first-column {
  width: 485px;
  background-color: #3498db; // Replace with your desired color
  padding: 16px; // Adjust padding as needed
}

.gap {
  width: 75px;
}

.second-column {
  width: 1240px;
  background-color: #2ecc71; // Replace with your desired color
  padding: 16px; // Adjust padding as needed
}

.black-and-white-toast {
  background-color: black;
  color: white;
}
