.bg-blue {
  background-color: #00439c;
}

.bg-gray {
  background: linear-gradient(to bottom, rgba(225, 227, 230, 1) 0%, rgba(225, 227, 230, 0) 100%);
}

.text-black {
  color: #333333;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 1.5rem;
}

.w-22 {
  width: 22rem;
}

.w-24 {
  width: 24rem;
}

.w-26 {
  width: 26rem;
}

.w-28 {
  width: 28rem;
}

.w-32 {
  width: 32rem;
}

.w-34 {
  width: 34rem;
}

.h-2-7 {
  height: 2.7rem;
}

.text-lg {
  font-size: large;
}
