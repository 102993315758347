.custom-toast {
    background: linear-gradient(to bottom, rgba(225, 227, 230, 1) 0%, rgba(225, 227, 230, 0.0) 100%);
    color: #000; /* Change this to your desired text color */
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: myCustomAnimation 0.5s ease-in-out; /* Add your custom animation */
  }
  
  @keyframes myCustomAnimation {
    /* Define your custom animation here */
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  