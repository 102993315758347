.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
  padding: 8.5rem;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  height: 10rem;
  width: 18rem;
  transition: background 0.3s ease-in-out;
}

@media (min-width: 1400px) {
  .grid-container {
    padding: 3.7rem;
  }

  .grid-item {
    height: 9rem;
    width: 17rem;
  }
}

@media (min-width: 1400px) {
  .grid-container {
    padding: 3.7rem;
  }

  .grid-item {
    height: 9rem;
    width: 17rem;
  }
}

.grid-item.large {
  grid-column: span 2;
}

.grid-item {
  font-size: 1rem;
  background-color: #333333;
  transition: background 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    background: linear-gradient(to bottom, #00439c 100%, #0059bd 0%);
  }
}

.macbook-button {
  position: relative;
  overflow: hidden;
  transition:
    transform 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.macbook-button:hover {
  transform: scale(0.98);
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 8px 16px rgba(0, 0, 0, 0.1);
}
