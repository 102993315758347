.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2rem;
  padding: 8.5rem 14rem;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  height: 10rem;
  width: 18rem;
  transition: background 0.3s ease-in-out;
}

.grid-item.large {
  grid-column: span 2;
}

.grid-item {
  font-size: 1rem;
  background-color: #333333;
  transition: background 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    background: linear-gradient(to bottom, #00439c 100%, #0059bd 0%);
  }
}
